.scanner-body {
  text-align: center;

  h4 {
    font-size: 14pt;
    margin: 0;
  }

  .part-title {
    margin-bottom: 1.5em;

    em {
      font-size: 1em;
      font-style: normal;
      color: #aaa;

      &:before {
        content: "(";
        margin-left: 0.25em;
      }
      &:after {
        content: ")";
      }
    }
  }

  .part-counter {
    input {
      width: 3em;
      font-size: 24pt;
      font-weight: bold;
      text-align: center;
      color: #000;
      display: inline-block;
      border-color: #eee;
      margin: 0 0.25em;
    }

    .controls {
      margin-top: 0.2em;
      display: inline-flex;
      align-items: center;
      justify-items: center;
      justify-content: space-between;
    }
  }

  .part-remaining {
    text-align: center;
    margin-top: 1em;
  }
}

.scanner-footer {
  justify-content: space-between;
}
