.userImage {
  margin-right: 0.5em;
}

.logout-btn {
  margin-left: 0.5em;
}

.user-status {
  @media all and (max-width: 1050px) {
    .profile-picture, .profile-name {
      display: none;
    }
  }
}