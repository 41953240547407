.btn-pink {
  $color: rgb(204, 0, 153);
  color: #fff;
  background-color: $color;
  border-color: $color;

  &:hover {
    color: #fff;
    background-color: darken($color, 10%);
    border-color: darken($color, 10%);
  }
}

.text-black {
  color: #212529;
}

.repair-serial {
  span {
    color: #a9a9a9;
  }
}

.repair-dates {
  position: relative;

  span:last-child {
    position: absolute;
    bottom: 0;
    left: .5rem;
    font-size: 8pt;
    color: #d1d1d1;

    @media all and (max-width: 850px) {
      display: none;
    }
  }
}

.bg-trash {
  background-color: #007bff !important;
  color: white;

  &:before {
    content: "\f2ed";
    display: inline;
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    font-style: normal;
    font-display: block;
    margin-right: 0.25em;
    color: white;
  }
}

.bg-diagnosis {
  background-color: #6b3400 !important;
  color: white;

  &:before {
    content: "\f059";
    display: inline;
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    font-style: normal;
    font-display: block;
    margin-right: 0.25em;
    color: white;
  }
}

.btn-diagnosis {
  background-color: #6b3400 !important;
  color: white;
}

.bg-ready-for-repair {
  background-color: #007602 !important;
  color: white;

  &:before {
    content: "\f058";
    display: inline;
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    font-style: normal;
    font-display: block;
    margin-right: 0.25em;
    color: white;
  }
}

.btn-ready-for-repair {
  background-color: #9d4c00 !important;
  color: white;
}

.bg-warning {
  color: #333;
}

.bg-light {
  color: #333;
  border: 1px solid #d3d3d3;
}

td.repairLink span[role="button"] {
  $color: #007bff;
  color: $color;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
    color: darken($color, 10%);
    cursor: pointer;

    &:after {
      position: absolute;
      font-family: "Font Awesome 5 Free";
      content: "\f044";
      margin-left: 0.5em;
      display: inline-block;
      font-weight: 400;
    }
  }
}

td.repair-person {
  max-width: 10%;
  width: 8%
}

tr.late-repair {
  background-color: #f8c798;

  .repair-dates {
    span {
      color: #333;
    }
  }
}
