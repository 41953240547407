.mongodb-status {
    display: inline-flex;
    align-items: center;
    margin-right: .5em;
}

.mongodb-status .badge {
    font-size: 10pt;
}

.mongodb-status .mongodb-icon {
    font-size: 15pt;
    margin-right: 6px;
}