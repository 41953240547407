.loader {
  position: relative;
  z-index: 1050;
  display: block;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
