.loginContainer {
  margin-top: 2em;
  text-align: center;

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button {
    flex: 1;
    margin-bottom: 0.5em;
  }

  h2 {
    text-align: center;
  }
}
