.servicePartsCointaner {
  display: flex;
}

.service-parts-container {
  position: relative;
}

.serviceParts {
  --gap: 16px;
  width: calc(60% + var(--gap));
  margin-right: var(--gap);
}

.servicePartsHistory {
  width: 45%;
}

.localCache {
  font-size: 7pt;
  color: #fafafa;
}

.toastAlert {
  position: absolute;
  top: 1em;
  right: 1em;
}

.barcodePeopleHelper span {
  font-size: 9pt;
  color: #777;
  display: block;
}

.scanningInfo {
  display: inline-block;
  position: relative;
  margin-left: 0.5rem;

  code {
    font-size: 12pt;
  }
}
