.partsHistoryContent {
  border: 1px solid rgb(222, 226, 230);
  padding: 16px;
  font-size: 11pt;
  line-height: 150%;
  height: 100%;
  overflow-y: auto;

  span {
    display: block;

    span {
      display: inline;
    }
  }

  small,
  em {
    color: #777;

    &.op-add {
      color: green;
      &:before {
        content: "+";
      }
    }

    &.op-del {
      color: red;
    }

    &.op-up {
      color: goldenrod;
      &:before {
        content: "+";
      }
    }
  }
}
