.print-status {
    display: inline-flex;
    align-items: center;
    margin-right: .5em;
}

.print-status .badge {
    font-size: 10pt;
}

.print-status .print-icon {
    font-size: 15pt;
    margin-right: 6px;
}