.itemsPerPage {
  margin-left: 0.5em;
  width: 60px;
  display: inline-block;
  text-align: right;
}

.prevNextOptions .pagination {
  justify-content: flex-end;
}
